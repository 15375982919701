<template>
  <div>
    <div class="xzbtn">
      <el-button type="primary" @click="dialogVisible = true">新增</el-button>
    </div>
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column align="center" label="姓名" >
        <template slot-scope="{row}">
          <el-input v-if="row.edit" v-model="edit_form.name" />
          <span v-else>{{row.name}}</span>
        </template>
      </el-table-column>
       <el-table-column align="center" label="标识项目">
        <template slot-scope="{row}">
         <router-link :to="'/user_user/enchilditem?userToken='+row.remember_token+'&id='+row.id+'&name='+row.name" class="linkto">
          <span>查看</span>
          </router-link>
        </template>
      </el-table-column>
       <el-table-column align="center" label="备案项目">
        <template slot-scope="{row}">
          <router-link :to="'/user_user/enchildrecord?userToken='+row.remember_token+'&id='+row.id+'&name='+row.name" class="linkto">
          <span>查看</span>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          label="账户"
          min-width="180">
        <template slot-scope="{row}">
          <el-input v-if="row.edit" v-model="edit_form.newUsername" />
          <span v-else>{{row.username}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="密码" >
        <template slot-scope="{row}">
          <el-input v-if="row.edit" v-model="edit_form.newPassword" placeholder="输入修改密码" />
          <span v-else>******</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="联系方式">
        <template slot-scope="{row}">
          <el-input v-if="row.edit" v-model="edit_form.telephone" />
          <span v-else>{{row.telephone}}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" label="职称" >
        <template slot-scope="{row}">
          <el-input v-if="row.edit" v-model="edit_form.work" />
          <span v-else>{{row.job}}</span>
        </template>
      </el-table-column> -->
      <el-table-column align="center" label="操作">
        <template  slot-scope="{row}" >
          <div v-if="!row.edit" @click="editChild(row)" style="color:#3086FB; cursor: pointer;">
            <i  class="el-icon-edit"></i>修改
          </div>
          <div v-else @click="updateChild(row)" style="color:#3086FB;cursor: pointer; ">
            <i  class="el-icon-edit"></i>确定
          </div>

        </template>
      </el-table-column>
    </el-table>
    <div class="bcbtn">
      <!-- <el-button type="primary">保存</el-button> -->
    </div>
    <!-- 弹窗 -->
    <el-dialog
        :visible.sync="dialogVisible"
        title="新增子账户"
        width="30%"
    >
      <div class="cptest">
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item label="姓名">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="账户">
            <el-input v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input v-model="form.password"></el-input>
          </el-form-item>
          <el-form-item label="联系方式">
            <el-input v-model="form.telephone"></el-input>
          </el-form-item>
          <!-- <el-form-item label="职称">
            <el-input v-model="form.work"></el-input>
          </el-form-item> -->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addChild"
        >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {addChildApi, getChildApi} from "../../../../api/project";

export default {
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      form: {},

      page: 1,
      limit: 10,
      totalPage:0,
      totalCount:0,
      searchName: '',
      edit_form:{},
    }
  },
  mounted() {
    this.getChildList();
  },
  methods: {
    updateChild(row){
      let params = {
        name: this.edit_form.name,
        telephone: this.edit_form.telephone,
        id: this.edit_form.id,
      };
      if (this.edit_form.newUsername!=this.edit_form.username){
        params.username = this.edit_form.newUsername;
      }
      if (this.edit_form.newPassword && this.edit_form.newPassword!=''){
        params.password = this.edit_form.newPassword;
      }
      params.token = this.$store.state.token;
      let that = this;
      addChildApi(params).then(res => {
        console.log(res);
        that.$message.success('更新成功');
        that.getChildList();
      })
      row.edit = false;
    },
    editChild(row){
      this.edit_form = row;
      this.edit_form.newUsername = this.edit_form.username;
      row.edit = true;
    },
    //获得子账户列表
    getChildList() {
      let params = {
        page: this.page,
        limit: this.limit,
        name: this.searchName,
        token: this.$store.state.token
      }
      let that = this;
      getChildApi(params).then(res => {
        console.log(res);
        let tableData = res.data;
        for (let i=0;i<tableData.length;i++){
          tableData[i].edit = false;
        }
        that.tableData = tableData;
        that.totalCount = res.totalCount;
        that.totalPage = res.totalPage;
      })
    },

    //添加子账户
    addChild() {
      let params = Object.assign({}, this.form);
      params.token = this.$store.state.token;
      let that = this;
      addChildApi(params).then(res => {
        console.log(res);
        that.$message.success('添加成功');
        that.getChildList();
        that.dialogVisible = false;
      })

    }
  }
}
</script>
<style scoped>
/deep/ .el-dialog__headerbtn .el-dialog__close {
  color: #ffffff;
}

/deep/ .el-dialog__title {
  color: #ffffff;
}

/deep/ .el-dialog {
  border-radius: 10px;
}

/deep/ .el-dialog__header {
  background-color: #3086fb;
  font-weight: bold;
  border-radius: 5px 5px 0 0;
}
.linkto{
  color: #3086fb;
  text-decoration: none;
}
.xzbtn {
  display: flex;
  flex-direction: row-reverse;
  margin: 10px 0;
}

.bcbtn {
  width: 100%;
  text-align: center;
  margin-top: 140px;
}
</style>
